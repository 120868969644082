.content-heading {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 8px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 25px;
    align-items: center;
    padding-right: 90px;
}

.title-block h2:before {
    content: "";
    position: absolute;
    bottom: -11px;
    left: 0;
    width: 100%;
    height: 3px;
    background: #f03333;
}

.title-block h2 {
    margin: 0;
    font-size: 22px;
    position: relative;
    display: inline-block;
}

.list-link {
    max-width: 400px;
}
.list-link {
    padding-left: 30px;
}

.list-link ul {
    white-space: nowrap;
    overflow: auto;
}

.list-link ul {
    list-style: none;
    padding: 0;
}

.list-link ul li.active {
    color: #f03333;
    border-color: #f03333;
}
.list-link ul li {
    display: inline-block;
    margin-right: 10px;
    font-size: 13px;
    font-weight: 500;
    line-height: 23px;
    border: 1px dashed transparent;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 0 15px;
    color: #636363;
}

.items {
    padding: 0 15px;
}
.product-wapper.content-product4 {
    position: relative;
}

.product-wapper.content-product7 {
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    overflow: hidden;
}

.product-wapper {
    position: relative;
    padding: 0;
    margin: 0 0 25px;
}
.product-wapper.content-product4:hover {
    border: none;
}

.product-wapper.content-product4:before {
    position: absolute;
    height: 100%;
    width: 1px;
    background: #e9e9e9;
    content: "";
    right: -15px;
}
.product-wapper .products-thumb {
    position: relative;
    margin: 0;
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
}
.product-wapper .products-thumb .product-thumb-hover {
    position: relative;
    display: block;
}
.product-wapper .products-thumb .product-thumb-hover .wp-post-image {
    opacity: 1;
    visibility: visible;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    height: auto;
}

.product-wapper .products-thumb .product-thumb-hover .hover-image {
    opacity: 0;
    visibility: hidden;
    max-width: 100%;
    height: auto;
}

.hot, .onsale {
    text-align: center!important;
    color: #fff;
    font-size: 11px;
    padding: 0 5px;
    text-transform: capitalize;
    font-weight: 500;
    display: block;
    position: relative;
    line-height: 18px;
    text-align: center;
    position: absolute;
    top: 10px;
    min-width: 40px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    z-index: 1;
}
.hot {
    right: 10px;
    background: #f90;
}
.onsale {
    left: 10px;
    background: #ff0404;
}
.hot:empty, .onsale:empty {
    display: none;
}

.product-wapper:hover .products-thumb .product-thumb-hover .wp-post-image {
    opacity: 0;
    visibility: hidden;
}
.product-wapper:hover .products-thumb .product-thumb-hover .hover-image {
    opacity: 1;
    visibility: visible;
}

.product-wapper .products-thumb .product-button {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px 15px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
    background: #fff;
}

.product-wapper:hover .products-thumb .product-button {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.product-wapper .products-thumb .product-button>* {
    margin: 0 5px;
    position: relative;
}

.product-wapper .products-thumb .product-button .add_to_cart_button, .product-wapper .products-thumb .product-button .added_to_cart, .product-wapper .products-thumb .product-button .product_type_external, .product-wapper .products-thumb .product-button .product_type_grouped, .product-wapper .products-thumb .product-button .product_type_variable, .product-wapper .products-thumb .product-button .read_more {
    color: #fff;
    display: inline-block;
    text-align: center;
    background: #fff;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 0;
    white-space: nowrap;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    flex: 0 0 35px;
    position: relative;
}

.product-wapper .products-thumb .product-button .add_to_cart_button:hover, .product-wapper .products-thumb .product-button .added_to_cart:hover, .product-wapper .products-thumb .product-button .product_type_external:hover, .product-wapper .products-thumb .product-button .product_type_grouped:hover, .product-wapper .products-thumb .product-button .product_type_variable:hover, .product-wapper .products-thumb .product-button .read_more:hover {
    background: #f03333;
    border-color: #f03333;
    color: #fff;
}
.product-wapper .products-thumb .product-button .read_more:before {
    content: "\e901";
    font-family: icomoon;
    font-size: 14px;
    line-height: 35px;
    color: #000;
    transition: all .1s ease;
}
.product-wapper .products-thumb .product-button .woosw-btn {
    font-size: 0;
    background: #fff;
    text-align: center;
    position: relative;
    white-space: nowrap;
    margin-top: 0;
    color: #fff;
    width: 35px;
    height: 35px;
    line-height: 35px;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 0;
    cursor: pointer;
}

.product-wapper .products-thumb .product-button .woosw-btn:before {
    content: "\e901";
    font-family: icomoon;
    font-size: 14px;
    line-height: 35px;
    color: #000;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
}
 .product-wapper .products-thumb .product-button .woosc-btn {
    text-align: center;
    background: #fff;
    font-size: 0;
    display: block;
    white-space: nowrap;
    color: #000;
    width: 35px;
    height: 35px;
    line-height: 35px;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 0;
    cursor: pointer;
}
.product-wapper .products-thumb .product-button .woosc-btn:before {
    font-size: 14px;
    color: #000;
    line-height: 35px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    content: "\e90a";
    font-family: icomoon;
    margin: 0;
}
.product-wapper .products-thumb .product-button .product-quickview {
    text-align: center;
    background: #fff;
    font-size: 0;
    display: block;
    white-space: nowrap;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.product-wapper .products-thumb .product-button .product-quickview>a {
    color: #000;
    width: 35px;
    height: 35px;
    line-height: 35px;
    display: inline-block;
    position: relative;
    outline: unset;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.product-wapper .products-thumb .product-button .product-quickview>a>i {
    font-size: 16px;
    color: #000;
    line-height: 35px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
}
.icon-visibility:before {
    content: "\e916";
}
.product-wapper .products-thumb .product-button .product-quickview:hover>a,
.product-wapper .products-thumb .product-button .woosc-compare:hover>button,
.product-wapper .products-thumb .product-button .woosw-wishlist:hover>button
 {
    background: #f03333;
    border-color: #f03333;
}

.product-wapper .products-content {
    margin-top: 20px;
}
.product-wapper.content-product7 .products-content {
    padding: 20px;
    margin: 0;
    min-height: 100px;
}
.rating.none{
    display: none;
}
.product-wapper .products-content .cat-products {
    font-size: 12px;
    margin-bottom: 5px;
}
.product-wapper .products-content .cat-products a {
    color: #636363;
}
.product-wapper .products-content h3.product-title {
    font-size: 14px;
    margin-top: 0;
    display: block;
    margin-bottom: 7px;
    font-weight: 500;
    font-family: Poppins,sans-serif;
}
.price {
    line-height: 24px;
    font-size: 18px;
    color: #000;
    font-weight: 400;
}
.product-wapper .products-content .price {
    line-height: 100%;
    display: inline-block;
}

.product-wapper.content-product4 .btn-atc {
    margin-top: 10px;
}

.product-wapper.content-product4 .btn-atc a {
    display: inline-block;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 30px;
    padding: 0 10px;
    min-width: 120px;
    background: #000;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    text-align: center;
    position: relative;
}
.product-wapper.content-product4 .btn-atc a:before {
    content: "\e900";
    font-family: icomoon;
    font-size: 0;
    color: #fff;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    margin: 0;
    display: inline-block;
    position: absolute;
    left: calc(50% - 7px);
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
}

.product-wapper.content-product4 .btn-atc a:hover {
    background: #f03333;
    font-size: 0;
}

.product-wapper.content-product4 .btn-atc a:hover:before {
    font-size: 14px;
}
.product-wapper.content-product4 .btn-atc a:before {
    content: "\e900";
    font-family: icomoon;
    font-size: 0;
    color: #fff;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    margin: 0;
    display: inline-block;
    position: absolute;
    left: calc(50% - 7px);
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
}

/*Banner*/
.banner-wrapper{
    position: relative;
}
.banners>div a {
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: top;
}

.bwp-widget-banner.layout-1 .banner-wrapper-infor {
    position: absolute;
    width: 100%;
    left: 0;
    padding: 0 25px;
    bottom: 30px;
}
.bwp-widget-banner.layout-1 .bwp-image-subtitle {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
}
.bwp-widget-banner.layout-1 .title-banner {
    font-size: 20px;
    margin: 0 0 10px;
    text-transform: uppercase;
}
.bwp-widget-banner.layout-1 .button, .bwp-widget-banner.layout-1 .button:after {
    display: inline-block;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
}
.bwp-widget-banner.layout-1 .button {
    font-size: 12px;
    font-weight: 500;
    line-height: 28px;
    padding: 0 10px;
    background: #fff;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.bwp-widget-banner.layout-1 .button:after {
    content: "\e90b";
    font-family: icomoon;
    width: 20px;
    height: 20px;
    background: #f03333;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    color: #fff;
    line-height: 20px;
    text-align: center;
    font-size: 7px;
    position: relative;
    margin-left: 3px;
    right: -2px;
    top: -1px;
}